import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToCart } from '../../../components/actions/cartActions';
import Popup from 'react-popup';
import '../../../Products.css';
//import '../../../materialize.css';
import CSS from 'csstype';
import Logo from '../../../components/Little-Logo-Button';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Arrow from '../../../components/Page-Navigator';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import { MeQuery } from '../../../schemaTypes';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const meQuery = gql`
    query MeQuery {
        me {
            id
            email
        }
    }
`;

// Because this is typescript, we need to know what types the State/Props are.
// unfortunately... with redux we don't know so we have to explicitly make them any here.

export interface State {
    items: any,
    category: string,
    addToCart: any,
    pages: boolean,
    counter: number,
    pageNumber: number,
    fontStyles: CSS.Properties;
    logoStyles: CSS.Properties;
    navigationStyle1: CSS.Properties;
    navigationStyle2: CSS.Properties;
    navigationStyle3: CSS.Properties;
    navigationStyle4: CSS.Properties;
    navigationStyle5: CSS.Properties;
    navigationStyle6: CSS.Properties;
    navigationStyle7: CSS.Properties;
    navigationStyle8: CSS.Properties;
}

export interface Props {
    messageOpen: boolean
}

var Image_Preview = {
    width:'300px',
    height:'300px'
}

class ProductsPage extends Component<any,State,any>{

    constructor(props: Props) {
        super(props); // Because this class inherts the React.Component, this will initialize the Component itself.
        
        this.state = { 
            items: this.props.items,
            addToCart: this.props.addToCart,
            category: "",
            pages: false,
            pageNumber: 1,
            counter: 1,
            fontStyles: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px'
            }, logoStyles: {
                textAlign: 'center'
            }, navigationStyle1: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle2: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle3: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle4: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle5: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle6: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle7: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle8: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }    
        };

        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMultiplePages = this.onMultiplePages.bind(this);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }

    onMouseEnter(e: any) {
        let hoverItem: number = +e.target.id;

        switch(hoverItem){
            case 1:
                this.setState({
                    navigationStyle1: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 2:
                this.setState({
                    navigationStyle2: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 3:
                this.setState({
                    navigationStyle3: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 4:
                this.setState({
                    navigationStyle4: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 5:
                this.setState({
                    navigationStyle5: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 6:
                this.setState({
                    navigationStyle6: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 7:
                this.setState({
                    navigationStyle7: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 8:
                this.setState({
                    navigationStyle8: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            default:
                console.log("No such day exists!");
                break;
        }
    }

    onMouseLeave(e: any) {
        let hoverItem: number = +e.target.id;

        switch(hoverItem){
            case 1:
                this.setState({
        
                    navigationStyle1: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 2:
                this.setState({
        
                    navigationStyle2: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 3:
                this.setState({
        
                    navigationStyle3: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 4:
                this.setState({
        
                    navigationStyle4: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 5:
                this.setState({
        
                    navigationStyle5: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 6:
                this.setState({
        
                    navigationStyle6: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 7:
                this.setState({
        
                    navigationStyle7: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 8:
                this.setState({
        
                    navigationStyle8: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            default:
                console.log("No exists!");
                break;
        }
    }

    handleClick = (id: any) =>{
        this.props.addToCart(id);
        Popup.alert('I am alert, nice to meet you');
    }

    numberToWords(num:any){
        switch(num){
            case 1:
                return "Item-One";
                break;
            case 2:
                return "Item-Two";
                break;
            case 3:
                return "Item-Three";
                break;
            case 4:
                return "Item-Four";
                break;
            case 5:
                return "Item-Five";
                break;
            case 6:
                return "Item-Six";
                break;
            case 7:
                return "Item-Seven";
                break;
            case 8:
                return "Item-Eight";
                break;
            default:
                return "new-page";
                break;
        }
    }

    updateCounter(){
        var num = this.state.counter;
        num+=1;
        this.setState({
            counter: num
        });
    }

    onMultiplePages(){
        this.setState({
            pages: true
        });
    }

    componentDidMount() {
        let category: string = this.props.match.params.category;

        this.setState({
            category: category
        });
    }

    componentDidUpdate() {
        let category: string = this.props.match.params.category;

        const pageString: string = this.props.match.params.page_number;
        const num: number = Number(pageString);

        console.log(category);
        
        if(num != this.state.pageNumber){
            this.forceUpdate();
            this.setState({
                pageNumber: num
            });
        }

        if(category !== this.state.category){
            this.forceUpdate();
            this.setState({
                category: category
            });
        }

    }

    mountArrow(totalPages: any){

        if(this.state.pages === true){
            
            // Case 1 - We are between the first and last page (if)
            // Case 2 - We are at the first page               (else if)
            // Case 3 - We are at the last page                (else)

            if( (this.state.pageNumber >= 2) && ((this.state.pageNumber+1) <= totalPages) ){
                console.log("CASE 1!");
                var uri = `${ (this.state.pageNumber-1) }`;
                var uri2 = `${ (this.state.pageNumber+1) }`;
                return(
                    <div className="Page-Navigation" id="Page-Navigation">
                        <a style={{display:"inline-block"}}>
                            <div className="rotate-translate">
                                <Link to={uri}>
                                    <Arrow/>
                                </Link>
                            </div>
                        </a>
                        <a style={{display:"inline-block"}}>
                            <Link to={uri2}>
                                <Arrow/>
                            </Link>
                        </a>
                    </div>
                )
            } else if(((this.state.pageNumber-1) == 0)) {
                console.log("CASE 2!!!!");
                var uri = `${(this.state.pageNumber+1)}`;
                return(
                    <div className="Page-Navigation" id="Page-Navigation">
                        <Link to={uri}>
                            <Arrow/>
                        </Link>
                    </div>
                )
            } else {
                console.log("CASE 3!!!!");
                var uri = `${(this.state.pageNumber-1)}`;
                return(
                    <div className="Page-Navigation" id="Page-Navigation">
                        <div className="rotate-translate">
                            <Link to={uri}>
                                <Arrow/>
                            </Link>
                        </div>
                    </div>
                )
            }

        } else {
            return(
                <div className="Page-Navigation" id="Page-Navigation">
                </div>
            )
        }

    }
    
    forceUpdateHandler(){
        
        this.forceUpdate();
        
    };

    /*
    <span id={(item.id).toString()} className="btn-floating halfway-fab waves-effect waves-light blue" onClick={()=>{this.handleClick(item.id)}}>
        <i className="material-icons">add</i>
    </span>
    */
   
    render(){

        var someNum: number = 0;
        var pageCount: number = 1;
        var pageCategory: string = this.state.category;
        var currentPage: number = this.state.pageNumber;
        let itemCount: number = 0;

        // // // // // // // // // // // // // // // //
        //
        //        E X T R E M E L Y    sub-optimal, 
        //
        // rework these ASAP.
        // 

        // This essentially counts how many pages we need.
        let emptyList = this.props.items.map( (item: any) => { 

            // Count the number of items
            if((item.category).toString() === pageCategory){
                itemCount += 1;
            }
            
            // Count the number of pages
            if ( ( ( (itemCount) % 9 ) == 0) && (itemCount != 0) ) {
                pageCount += 1;

                if(this.state.pages == false){
                    this.onMultiplePages();
                }
                
                if(pageCount != this.state.pageNumber){
                    
                }

            } 

        })

        // This determines what items to render.
        let itemList = this.props.items.map( (item: any) => {

            // Checks to see if we are going to render the 
            // appropriate categorical item

            if((someNum < (currentPage*9)) && (someNum >= ((currentPage-1)*9)) && ((item.category).toString() === pageCategory) || (pageCategory === "all")){
                
                var itemPosition = (someNum%9)+1;
                someNum += 1;
                var productUri: string = `/item/${item.id}`;

                return(
                    <div className={this.numberToWords(itemPosition)}>
                        <div className="card" key={item.id}>
                            <div className="card-image">
                                <Link to={productUri}>
                                    <img src={item.img_preview} alt={item.title} style={Image_Preview}/>
                                    <span className="card-title"></span>
                                </Link>
                            </div>

                            <Link to={productUri}>
                                <div className="card-content">
                                    <p style={{textAlign: "left", color: "black", fontSize: "20px"}}>{item.title.toUpperCase()}</p>
                                    <p style={{textAlign: "left", color: "black", fontSize: "24px"}}>PRICE: ${item.price}</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                )

            // Checks to see if we have exceeded the number of items we can have
            // on this current page... render the page navigator and show the number of pages
            } else if(((item.category).toString() === pageCategory)){
                someNum += 1;
            }

        })

        let mobileItemList = this.props.items.map( (item: any) => {

            // Checks to see if we are going to render the 
            // appropriate categorical item

            if(((item.category).toString() === pageCategory) || (pageCategory === "all")){
                var productUri: string = `/item/${item.id}`;
                return(
                    <div className="container_row_2">
                        <div className="card" key={item.id}>
                            <div className="card-image">
                                <Link to={productUri}>
                                    <img src={item.img_preview} alt={item.title} style={Image_Preview}/>
                                    <span className="card-title"></span>
                                </Link>
                            </div>

                            <Link to={productUri}>
                                <div className="card-content">
                                    <p style={{textAlign: "left", color: "black", fontSize: "20px"}}>{item.title.toUpperCase()}</p>
                                    <p style={{textAlign: "left", color: "black", fontSize: "24px"}}>PRICE: ${item.price}</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                )

            } 
        })

        //
        // // // // // // // // // // // // // // // //

        console.log(pageCount + " PAGES!!!");

        if((pageCount == 1) && (this.state.pages == true)){
            this.setState({
                pages: false
            })
        }

        if(pageCategory != ""){
            return(
                <div>
                    <BrowserView>
                        <div className="grid-container-2">
                            <div className="Navigation">

                                <Link to="/store/products/all/1">
                                    <a id="1" style={this.state.navigationStyle1} 
                                    onMouseLeave={this.onMouseLeave}
                                    onMouseEnter={this.onMouseEnter}>ALL ITEMS</a> 
                                </Link>

                                <br/>

                                <Link to="/store/products/tshirts/1">
                                    <a id="3" style={this.state.navigationStyle3} 
                                    onMouseLeave={this.onMouseLeave}
                                    onMouseEnter={this.onMouseEnter}>TSHIRTS</a> 
                                </Link>
                                <br/>

                                <Link to="/store/products/shirts/1">
                                    <a id="4" style={this.state.navigationStyle4} 
                                    onMouseLeave={this.onMouseLeave}
                                    onMouseEnter={this.onMouseEnter}>SHIRTS/POLOS</a>
                                </Link>

                                <br/>
                                
                                <Link to="/store/products/hoodies/1">
                                    <a id="5" style={this.state.navigationStyle5} 
                                    onMouseLeave={this.onMouseLeave}
                                    onMouseEnter={this.onMouseEnter}>HOODIES/SWEATERS</a> 
                                </Link>

                                <br/>

                                <Link to="/store/products/jackets/1">
                                    <a id="6" style={this.state.navigationStyle6} 
                                    onMouseLeave={this.onMouseLeave}
                                    onMouseEnter={this.onMouseEnter}>JACKETS</a> 
                                </Link>
                                <br/>

                                <Link to="/store/products/bottoms/1">
                                    <a id="7" style={this.state.navigationStyle7} 
                                    onMouseLeave={this.onMouseLeave}
                                    onMouseEnter={this.onMouseEnter}>BOTTOMS</a>
                                </Link>

                                <br/>

                                <Link to="/store/products/accessories/1">
                                    <a id="8" style={this.state.navigationStyle8} 
                                    onMouseLeave={this.onMouseLeave}
                                    onMouseEnter={this.onMouseEnter}>ACCESSORIES</a>
                                </Link>

                                <br/>

                                <Link to="/store/products/footwear/1">
                                    <a id="2" style={this.state.navigationStyle2} 
                                    onMouseLeave={this.onMouseLeave}
                                    onMouseEnter={this.onMouseEnter}>FOOTWEAR</a>
                                </Link>


                            </div>
                            <div className="Logo" style={this.state.logoStyles}>
                                <Link to="/">
                                    <Logo/>
                                </Link><br/>
                                <a style={{color: "black"}}>{pageCategory.toUpperCase()}</a>
                            </div>
                            <Query<MeQuery> 
                                query={meQuery}>{({data, loading}) => { 

                                    if(loading) { 
                                        return (
                                            <div className="User-Links">
                                                <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                                <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                            </div>
                                        )
                                    }
                    
                                    if(!data){
                                        return (
                                            <div className="User-Links">
                                                <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                                <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                            </div>
                                        )
                                    }
                    
                                    if(!data.me){
                                        return (
                                            <div className="User-Links">
                                                <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                                <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                            </div>
                                        )
                                    }
                                    return(
                                        <div className="User-Links">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                        </div>
                                    )  
                                }}
                            </Query>
                            {itemList}
                            {this.mountArrow(pageCount)}
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className="mobile-container">
                            <div className="mobile-header" style={this.state.logoStyles}>
                                <Link to="/">
                                    <Logo/>
                                </Link><br/>
                                <a style={{color: "black"}}>{pageCategory.toUpperCase()}</a>
                                <Query<MeQuery> 
                                    query={meQuery}>{({data, loading}) => { 

                                        if(loading) { 
                                            return (
                                                <div className="User-Links">
                                                    <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                                    <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                                </div>
                                            )
                                        }
                        
                                        if(!data){
                                            return (
                                                <div className="User-Links">
                                                    <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                                    <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                                </div>
                                            )
                                        }
                        
                                        if(!data.me){
                                            return (
                                                <div className="User-Links">
                                                    <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                                    <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                                </div>
                                            )
                                        }
                                        return(
                                            <div className="User-Links">
                                                <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                            </div>
                                        )  
                                    }}
                                </Query>
                            </div>
                            <div className="mobile-products-area">
                                {mobileItemList}
                            </div>
                            <div className="mobile-footer">
                            </div>
                        </div>
                    </MobileView>
                </div>
                
            )
        } else {
            return( 
                <div>Niggas is haters, I'm doing deals like the majors...</div>
            )
        }

    }
}

const mapStateToProps = (state: any) => {
    return {
      items: state.items
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return{   
        addToCart: (id: any) => { dispatch(addToCart(id)) }     
    }
}

  // @ts-ignore: Unreachable code error
  export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ProductsPage))
