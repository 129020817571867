import React from 'react';
import Root from './Root/Root'
import * as ReactDOM from 'react-dom';
import { register } from './serviceWorker';
import  ApolloClient  from 'apollo-boost';
import { ApolloProvider } from 'react-apollo'
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import CartReducer from './components/reducers/cartReducer';
import HttpsRedirect from 'react-https-redirect';

const store = createStore(CartReducer);

const client = new ApolloClient({
  uri: 'https://graphql.membergs.com/graphql',
  credentials: 'include', // For cookies
});

ReactDOM.render(
  <HttpsRedirect>
	  <Provider store={store}>
	    <ApolloProvider client={client}>
	      <Root />
	    </ApolloProvider>
	  </Provider>
  </HttpsRedirect>,
  document.getElementById('root')
);
register();

