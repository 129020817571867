import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import { MeQuery } from '../../../../schemaTypes';
import "../../../../remove.css";
import Logo from '../../../../components/Little-Logo-Button'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addToCart } from '../../../../components/actions/cartActions';
import axios from 'axios';

const meQuery = gql`
    query MeQuery {
        me {
            id
            email
        }
    }
`;

export interface State{
}

export interface Props{
}

class RemoveProduct extends Component<any,State,any> {

    constructor(props: Props){
        super(props);

        this.removeItem = this.removeItem.bind(this);
    }

    removeItem(e: any){

        let queryUrl: string = 'https://graphql.membergs.com/graphql';
    
        axios({
          url: queryUrl,
          method: 'post',
          data: {
            query:`mutation {
                removeProduct(id: ${e.target.id})
              }
            `
          }
        }).then((result) => {
          //console.log(result)
          this.forceUpdate();
            // @ts-ignore: Unreachable code error
          window.location.reload(false);
        });
    }

    render() {

        return( 
            
        <Query<MeQuery> 
            query={meQuery}>{({data, loading}) => { 
                
                if(loading) { 
                    return null;
                }

                if(!data){
                    return <div>Data is undefined!</div>;
                }

                if(!data.me){
                    return <div>No User!</div>;
                }

                return(
                    
                    <div className="grid-container-8">
                        <div className="Logo">
                            <Link to="/">
                                <Logo/>
                            </Link>
                        </div>
                        <div className="Options">
                            {
                                this.props.items.map( (item: any) => (
                                    <div className="grid-container-13"> 
                                        <div className="Remove-Btn">
                                            <button 
                                                className="waves-effect waves-light btn light-blue remove" 
                                                style={{backgroundColor: "black"}}
                                                id={item.id}
                                                onClick={this.removeItem}
                                            >Remove</button>
                                        </div>
                                        <div className="Product-Info">
                                            ID #{item.id} | Name: {item.title} 
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }}
        </Query>);
    }
}

const mapStateToProps = (state: any) => {
    return {
      items: state.items
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return{   
        addToCart: (id: any) => { dispatch(addToCart(id)) }     
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RemoveProduct);
