import {Component} from 'react';
import React from 'react';

class BlogRedirect extends Component<any,any,any>{
    componentDidMount(){
        console.log("HERE!");
        window.location.replace("https://blog.membergs.com/");
    }

    render(){
        return(
            <a></a>
        )
    }
}

export default BlogRedirect;