// eslint-disable-next-line
import * as React from 'react';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const SUBTRACT_QUANTITY = 'SUBTRACT_QUANTITY';
export const ADD_SHIPPING = 'ADD_SHIPPING';
export const SUB_SHIPPING = 'SUB_SHIPPING';

export const addToCart = (id: any)=>{return{    type: ADD_TO_CART,     id }}
export const removeItem = (id: any) => { return { type: REMOVE_ITEM, id}}
export const addQuantity = (id: any) => { return { type: ADD_QUANTITY, id}}
export const subtractQuantity = (id: any) => { return { type: SUBTRACT_QUANTITY, id}}
export const subtractShipping = (id: any) => { return { type: SUB_SHIPPING, id}}
export const addShipping = (id: any) => { return { type: ADD_SHIPPING, id}}