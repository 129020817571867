// eslint-disable-next-line
import React, { Component } from 'react'; 
import { ADD_TO_CART, REMOVE_ITEM, SUBTRACT_QUANTITY, ADD_QUANTITY, ADD_SHIPPING, SUB_SHIPPING } from '../actions/cartActions';

const response = async(currStateItems:any[]): Promise<any[]> => {
        
    const response = await fetch(`https://graphql.membergs.com/graphql?query={getProducts{id price inventory name category size description photo_album photo_preview}}`, {
	mode: 'cors',
	method: 'GET',
	cache: 'no-cache',
	credentials: 'include',
	headers: {
		'Content-Type': 'application/json'
	}
});
    const json = await response.json();
    const productsArray = json["data"]["getProducts"];
    console.log(productsArray);
    for (let product of productsArray){
        currStateItems.push({
            id: product["id"],
            title: product["name"],
            desc: product["description"],
            price: product["price"],
            img_preview: product["photo_preview"],
            img_library: product["photo_album"],
            inventory: product["inventory"],
            category: product["category"],
            size: product["size"]
        });
    }

    return new Promise((resolve) => {
        resolve(currStateItems);
    });
}

const initState = {
    items: [],
    addedItems:[],
    total: 0
}

// TO DO : get this state initialized before the page renders!
response(initState.items).then( function (itemsLoaded: any) {
    initState.items = itemsLoaded;
});

const CartReducer = (state = initState, action: any) => {


    if(action.type === ADD_TO_CART){

        let addedItem: any = state.items.find((item:any) => item.id === action.id)
        let existed_item: any = state.addedItems.find((item:any) => action.id === item.id)

        // Checks to see if the inventory allows more than
        // one item at a time.

        if(existed_item && (addedItem.inventory > 1))
        {
            alert("Item added!");
            if(addedItem.quantity <= (addedItem.inventory-1)){
                addedItem.quantity += 1 
                return{
                    ...state,
                    total: state.total + addedItem.price 
                }
            }
        }
        else if(!existed_item && (addedItem.inventory > 0)){
            alert("Item added!");
            addedItem.quantity = 1;
            //calculating the total
            let newTotal = state.total + addedItem.price 
            
            return{
                ...state,
                addedItems: [...state.addedItems, addedItem],
                total : newTotal
            }
        }else{
            console.log("shit-balls");
        }
    }
    if(action.type === REMOVE_ITEM){
        let itemToRemove: any = state.addedItems.find((item: any)=> action.id === item.id)
        let new_items: any = state.addedItems.filter((item: any)=> action.id !== item.id)
        
        //calculating the total
        let newTotal: any = state.total - (itemToRemove.price * itemToRemove.quantity )
        console.log(itemToRemove)
        return{
            ...state,
            addedItems: new_items,
            total: newTotal
        }
    }
    //INSIDE CART COMPONENT
    if(action.type=== ADD_QUANTITY){
        let addedItem: any = state.items.find((item: any)=> item.id === action.id)
          addedItem.quantity += 1 
          let newTotal: any = state.total + addedItem.price
          return{
              ...state,
              total: newTotal
          }
    }
    if(action.type=== SUBTRACT_QUANTITY){  
        let addedItem: any = state.items.find((item: any)=> item.id === action.id) 
        //if the qt == 0 then it should be removed
        if(addedItem.quantity === 1){
            let new_items: any = state.addedItems.filter((item: any)=>item.id !== action.id)
            let newTotal: any = state.total - addedItem.price
            return{
                ...state,
                addedItems: new_items,
                total: newTotal
            }
        }
        else {
            addedItem.quantity -= 1
            let newTotal = state.total - addedItem.price
            return{
                ...state,
                total: newTotal
            }
        }
        
    }
    if(action.type === ADD_SHIPPING){
        return{
            ...state,
            total: state.total + 6
        }
    }
    if(action.type === SUB_SHIPPING){
      return{
          ...state,
          total: state.total - 6
      }
    }
    
    else{
        return state
    }

}

export default CartReducer;
