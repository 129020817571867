import { PayPalButton } from "react-paypal-button-v2";
import { Component } from 'react';
import { connect } from 'react-redux';
import * as React from 'react';
import axios from 'axios';

export interface Props{
  totalAmt: any;
  itemIds: any;
}

export interface State{
  transactionComplete: boolean;
}
 
class PayPal extends Component<any,any,any> {

    constructor(props: Props){
      super(props);

      this.state = {transactionComplete: false};
    }

    render() {

      return (
        <PayPalButton
          amount={this.props.totalAmt}
          onSuccess={(details: any, data: any) => {
            alert("Transaction completed by " + details.payer.name.given_name);
            
            let i: number = 0;
            let stop: number = (this.props.itemIds).length;
            while(i < stop){

              let queryUrl: string = 'https://graphql.membergs.com/graphql';
              axios({
                url: queryUrl,
                method: 'post',
                data: {
                  query:`mutation {
                      removeProduct(id: ${this.props.itemIds[i]})
                    }
                  `
                }
              }).then((result: any) => {
                // @ts-ignore: Unreachable code error
                window.location.reload(false);
                this.setState({
                  transactionComplete: true
                });

              });

              i += 1;
            }

          }}
        />
      );
    }
  }

const mapStateToProps = (state: any) => {
    return {
      items: state.items
    }
  }

export default connect(mapStateToProps)(PayPal)
