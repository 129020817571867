import * as React from 'react';
import { Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import { RegisterMutation, RegisterMutationVariables } from '../../../schemaTypes';
import { RouteComponentProps } from 'react-router';

const registerMutation = gql`
    mutation RegisterMutation($email: String!, $password: String!){
        register(email: $email, password: $password)
    }
`;

class RegisterView extends React.PureComponent<RouteComponentProps<{}>> {

    state = {
        email: '',
        password: ''
    }

    handleChange = (e: any) => {
        const { name, value } = e.target;
        this.setState({
            [name]:value
        });
    };

    render(){
        const {password, email} = this.state;

        return(
            <Mutation<RegisterMutation, RegisterMutationVariables> mutation={registerMutation}>
                {mutate => (
                        <div>
                            <input 
                                type="text"
                                placeholder="email" 
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                            />
                            <input 
                                type="password"
                                placeholder="password" 
                                name="password"
                                value={password}
                                onChange={this.handleChange}
                            />
                            <button onClick={async () => {
                                const Response = await mutate({variables: this.state});
                                console.log(Response);
                                this.props.history.push('/');
                            }}>
                                Register
                            </button>
                        </div>
                )}
            </Mutation>
        );
    }
}
export default RegisterView;