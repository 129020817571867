import React, { Component } from 'react';
import { removeItem,addQuantity,subtractQuantity} from '../../../components/actions/cartActions';
import Logo from '../../../components/Little-Logo-Button';
import StoreButton from '../../../components/Store-Buttons';
import '../../../Store.css';
import CSS from 'csstype';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MeQuery } from '../../../schemaTypes';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export interface State {
    fontStyles: CSS.Properties;
    logoStyles: CSS.Properties;
    navigationStyle1: CSS.Properties;
    navigationStyle2: CSS.Properties;
    navigationStyle3: CSS.Properties;
    navigationStyle4: CSS.Properties;
    navigationStyle5: CSS.Properties;
    navigationStyle6: CSS.Properties;
    navigationStyle7: CSS.Properties;
    navigationStyle8: CSS.Properties;
}

const meQuery = gql`
    query MeQuery {
        me {
            id
            email
        }
    }
`;

export interface Props{
}

// Because this is typescript, we need to know what types the State/Props are.
// unfortunately... with redux we don't know so we have to explicitly make them any here.

class StoreHome extends Component<Props, State>{

    constructor(props: Props) {
        super(props); // Because this class inherts the React.Component, this will initialize the Component itself.

        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);

        this.state = { 
            fontStyles: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px'
            }, logoStyles: {
                textAlign: 'center'
            }, navigationStyle1: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle2: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle3: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle4: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle5: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle6: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle7: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }, navigationStyle8: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px',
                paddingLeft: '20px',
                textAlign: 'left'
            }    
        };

    }

    onMouseEnter(e: any) {
        let hoverItem: number = +e.target.id;

        switch(hoverItem){
            case 1:
                this.setState({
                    navigationStyle1: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 2:
                this.setState({
                    navigationStyle2: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 3:
                this.setState({
                    navigationStyle3: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 4:
                this.setState({
                    navigationStyle4: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 5:
                this.setState({
                    navigationStyle5: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 6:
                this.setState({
                    navigationStyle6: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 7:
                this.setState({
                    navigationStyle7: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            case 8:
                this.setState({
                    navigationStyle8: {
                        color: "#02FBF7",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
                });
                break;
            default:
                console.log("No such day exists!");
                break;
        }
    }

    onMouseLeave(e: any) {
        let hoverItem: number = +e.target.id;

        switch(hoverItem){
            case 1:
                this.setState({
        
                    navigationStyle1: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 2:
                this.setState({
        
                    navigationStyle2: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 3:
                this.setState({
        
                    navigationStyle3: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 4:
                this.setState({
        
                    navigationStyle4: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 5:
                this.setState({
        
                    navigationStyle5: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 6:
                this.setState({
        
                    navigationStyle6: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 7:
                this.setState({
        
                    navigationStyle7: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            case 8:
                this.setState({
        
                    navigationStyle8: {
                        color: "black",
                        fontSize: '16px',
                        paddingRight: '20px',
                        paddingLeft: '20px',
                        textAlign: 'left'
                    }
        
                });
                break;
            default:
                console.log("No exists!");
                break;
        }
    }

    render(){
        return(
            <div>
                <BrowserView>
                    <div className="grid-container">
                        <div className="Navigation">

                            <Link to="/store/products/all/1">
                                <a id="1" style={this.state.navigationStyle1} 
                                onMouseLeave={this.onMouseLeave}
                                onMouseEnter={this.onMouseEnter}>ALL ITEMS</a> 
                            </Link>

                            <br/>

                            <Link to="/store/products/tshirts/1">
                                <a id="3" style={this.state.navigationStyle3} 
                                onMouseLeave={this.onMouseLeave}
                                onMouseEnter={this.onMouseEnter}>T-SHIRTS</a> 
                            </Link>
                            <br/>

                            <Link to="/store/products/shirts/1">
                                <a id="4" style={this.state.navigationStyle4} 
                                onMouseLeave={this.onMouseLeave}
                                onMouseEnter={this.onMouseEnter}>SHIRTS/POLOS</a>
                            </Link>

                            <br/>
                            
                            <Link to="/store/products/hoodies/1">
                                <a id="5" style={this.state.navigationStyle5} 
                                onMouseLeave={this.onMouseLeave}
                                onMouseEnter={this.onMouseEnter}>HOODIES/SWEATERS</a> 
                            </Link>

                            <br/>

                            <Link to="/store/products/jackets/1">
                                <a id="6" style={this.state.navigationStyle6} 
                                onMouseLeave={this.onMouseLeave}
                                onMouseEnter={this.onMouseEnter}>JACKETS</a> 
                            </Link>
                            <br/>

                            <Link to="/store/products/bottoms/1">
                                <a id="7" style={this.state.navigationStyle7} 
                                onMouseLeave={this.onMouseLeave}
                                onMouseEnter={this.onMouseEnter}>BOTTOMS</a>
                            </Link>

                            <br/>

                            <Link to="/store/products/accessories/1">
                                <a id="8" style={this.state.navigationStyle8} 
                                onMouseLeave={this.onMouseLeave}
                                onMouseEnter={this.onMouseEnter}>ACCESSORIES</a>
                            </Link>

                            <br/>

                            <Link to="/store/products/footwear/1">
                                <a id="2" style={this.state.navigationStyle2} 
                                onMouseLeave={this.onMouseLeave}
                                onMouseEnter={this.onMouseEnter}>FOOTWEAR</a>
                            </Link>


                        </div>
                    
                        <Query<MeQuery> 
                            query={meQuery}>{({data, loading}) => { 

                                if(loading) { 
                                    return (
                                        <div className="User-Links">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                            <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                        </div>
                                    )
                                }
                
                                if(!data){
                                    return (
                                        <div className="User-Links">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                            <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                        </div>
                                    )
                                }
                
                                if(!data.me){
                                    return (
                                        <div className="User-Links">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                            <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                        </div>
                                    )
                                }
                                return(
                                    <div className="User-Links">
                                        <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                    </div>
                                )  
                            }}
                        </Query>

                        <div className="Logo" style={this.state.logoStyles}>
                            <Link to="/">
                                <Logo/>
                            </Link>
                        </div>
                        <div className="Category-One">
                            <Link to="/store/products/tshirts/1">
                                <StoreButton category_type="t-shirts"/>
                            </Link>
                        </div>
                        <div className="Category-Two">
                            <Link to="/store/products/shirts/1">
                                <StoreButton category_type="shirts-polos"/>
                            </Link>
                        </div>
                        <div className="Category-Three">
                            <Link to="/store/products/hoodies/1">
                                <StoreButton category_type="hoodies"/> 
                            </Link>
                        </div>
                        <div className="Category-Four">
                            <Link to="/store/products/jackets/1">
                                <StoreButton category_type="jackets"/> 
                            </Link>
                        </div>
                        <div className="Category-Five">
                            <Link to="/store/products/bottoms/1">
                                <StoreButton category_type="bottoms"/>
                            </Link>
                        </div>
                        <div className="Category-Six">
                        <Link to="/store/products/accessories/1">
                                <StoreButton category_type="accessories"/>
                            </Link>
                        </div>
                        <div className="Category-Seven">
                            <Link to="/store/products/footwear/1">
                                <StoreButton category_type="footwear"/>
                            </Link>
                        </div>
                        <div className="Category-Eight">
                            <Link to="/store/products/all/1">
                                <StoreButton category_type="all"/>
                            </Link>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="store-mobile-container">
                        <div className="store-mobile-header">                
                            <div className="Logo" style={this.state.logoStyles}>
                                <Link to="/">
                                    <Logo/>
                                </Link>
                            </div>
                            <Query<MeQuery> 
                                query={meQuery}>{({data, loading}) => { 

                                    if(loading) { 
                                        return (
                                            <div className="User-Links">
                                                <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                                <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                            </div>
                                        )
                                    }
                    
                                    if(!data){
                                        return (
                                            <div className="User-Links">
                                                <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                                <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                            </div>
                                        )
                                    }
                    
                                    if(!data.me){
                                        return (
                                            <div className="User-Links">
                                                <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                                <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                            </div>
                                        )
                                    }
                                    return(
                                        <div className="User-Links">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                        </div>
                                    )  
                                }}
                            </Query>
                        </div>
                        <div className="store-mobile-products">
                            <div className="store-product-container-mobile">
                                <div className="mobile-product-one">
                                    <Link to="/store/products/tshirts/1">
                                        <StoreButton category_type="t-shirts"/>
                                    </Link>
                                </div>
                                <div className="mobile-product-two">
                                    <Link to="/store/products/shirts/1">
                                        <StoreButton category_type="shirts-polos"/>
                                    </Link>
                                </div>
                                <div className="mobile-product-three">
                                    <Link to="/store/products/bottoms/1">
                                        <StoreButton category_type="bottoms"/>
                                    </Link>
                                </div>
                                <div className="mobile-product-four">
                                    <Link to="/store/products/jackets/1">
                                        <StoreButton category_type="jackets"/> 
                                    </Link>
                                </div>
                                <div className="mobile-product-five">
                                    <Link to="/store/products/hoodies/1">
                                        <StoreButton category_type="hoodies"/> 
                                    </Link>
                                </div>
                                <div className="mobile-product-six">
                                    <Link to="/store/products/footwear/1">
                                        <StoreButton category_type="footwear"/>
                                    </Link>
                                </div>
                                <div className="mobile-product-seven">
                                    <Link to="/store/products/accessories/1">
                                        <StoreButton category_type="accessories"/>
                                    </Link>
                                </div>
                                <div className="mobile-product-eight">
                                    <Link to="/store/products/all/1">
                                        <StoreButton category_type="all"/>
                                    </Link>
                                </div>              
                            </div>
                        </div>
                    </div>
                </MobileView>
            </div>
        )

    }
}


const mapStateToProps = (state: any) => {
    return{
        items: state.addedItems
    }
}
const mapDispatchToProps = (dispatch: any)=>{
    return{
        removeItem: (id: any)=>{dispatch(removeItem(id))},
        addQuantity: (id: any)=>{dispatch(addQuantity(id))},
        subtractQuantity: (id: any)=>{dispatch(subtractQuantity(id))}
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(StoreHome);
