import * as React from "react";
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import { MeQuery } from '../../../schemaTypes';
import "../../../Admin.css";
import Logo from '../../../components/Little-Logo-Button'
import SimpleReactFileUpload from '../../../components/File-Upload'; 
import { Link } from 'react-router-dom';

const meQuery = gql`
    query MeQuery {
        me {
            id
            email
        }
    }
`;

export interface State{
    selected: string;
    sizeOptions: any;
    size: string;
    name: string;
    quantity: number;
    price: number;
    category: string;
    description: string;
    completedForm: boolean;
    uploadBtn: any;
}

export interface Props{

}

class AdminView extends React.PureComponent<any,State,any> {

    constructor(props: Props){
        super(props);

        this.state = { selected: "N/A", sizeOptions:<div></div>, uploadBtn:<div></div>,
        size:"", name:"", category: "", description: "", price:0.0, quantity:0, completedForm: false };

        this.updateSizeOptions = this.updateSizeOptions.bind(this);
        this.updateProps = this.updateProps.bind(this);
    }

    allowUpload(e: any){

        this.setState({
            uploadBtn: <button className="waves-effect waves-light btn light-blue remove"  style={{width: "100%"}}>Upload Product</button>
        });
    } 

    updateProps(e: any){
        let value: any = e.target.value;
        let target: string = e.target.id;

        if(target === "itemName"){
            this.setState({
                name: value
            });
        } else if (target === "itemDescription"){
            this.setState({
                description: value
            });
        } else if (target === "itemPrice"){
            this.setState({
                price: value
            });
        } else if (target === "itemQuantity"){
            this.setState({
                quantity: value
            });
        }
    }

    updateSizeOptions(e: any){
        const id = e.target.id;
        
        if(id.length > 2){
            if(id === "bottoms"){
                this.setState({
                    selected: id,
                    category: id,
                    sizeOptions: 
                    <div className="dropdown-2">
                        Sizes<br/>
                        <input style={{opacity: "100%", pointerEvents: "auto"}} 
                            type="radio" 
                            id="S" 
                            name="sizes" 
                            value="S"
                            onClick={this.updateSizeOptions}/>
                        <label htmlFor="S">Small</label><br/>
                        <input style={{opacity: "100%", pointerEvents: "auto"}} 
                            type="radio" 
                            id="M" 
                            name="sizes" 
                            value="M"
                            onClick={this.updateSizeOptions}/>
                        <label htmlFor="M">Medium</label><br/>
                        <input style={{opacity: "100%", pointerEvents: "auto"}} 
                            type="radio" 
                            id="L" 
                            name="sizes" 
                            value="L"
                            onClick={this.updateSizeOptions}/>
                        <label htmlFor="L">Large</label><br/>
                        <input style={{opacity: "100%", pointerEvents: "auto"}} 
                            type="radio" 
                            id="XL" 
                            name="sizes" 
                            value="XL"
                            onClick={this.updateSizeOptions}/>
                        <label htmlFor="XL">XLarge</label><br/>
                    </div>
                });
            } else if( (id === "accessories") || (id === "misc") ) {
                this.setState({
                    selected: id,
                    category: id,
                    sizeOptions:<div></div>
                })
            }  else {
                this.setState({
                    selected: id,
                    category: id,
                    sizeOptions: 
                    <div className="dropdown-2">
                        Sizes<br/>
                        <input style={{opacity: "100%", pointerEvents: "auto"}} 
                            type="radio" 
                            id="S" 
                            name="sizes" 
                            value="S"
                            onClick={this.updateSizeOptions}/>
                        <label htmlFor="S">Small</label><br/>
                        <input style={{opacity: "100%", pointerEvents: "auto"}} 
                            type="radio" 
                            id="M" 
                            name="sizes" 
                            value="M"
                            onClick={this.updateSizeOptions}/>
                        <label htmlFor="M">Medium</label><br/>
                        <input style={{opacity: "100%", pointerEvents: "auto"}} 
                            type="radio" 
                            id="L" 
                            name="sizes" 
                            value="L"
                            onClick={this.updateSizeOptions}/>
                        <label htmlFor="L">Large</label><br/>
                        <input style={{opacity: "100%", pointerEvents: "auto"}} 
                            type="radio" 
                            id="XL" 
                            name="sizes" 
                            value="XL"
                            onClick={this.updateSizeOptions}/>
                        <label htmlFor="XL">XLarge</label><br/>
                    </div>
                });
            }
        } else {
            this.setState({
                size: e.target.id
            });
        }

    }

    render() {
        return( 
        <Query<MeQuery> 
            query={meQuery}>{({data, loading}) => { 
                
                if(loading) { 
                    return null;
                }

                if(!data){
                    return <div>Data is undefined!</div>;
                }

                if(!data.me){
                    return <div>No User!</div>;
                }

                return(
                    <div className="grid-container-8">
                        <div className="Logo">
                            <Link to="/">
                                <Logo/>
                            </Link>
                        </div>
                        <div className="Options">
                            <h3>Adding Item</h3>
                                <label htmlFor="fname">Item Name </label>
                                <input type="text" id="itemName" name="fname"
                                    onChange={this.updateProps}></input>
                                <label htmlFor="lname">Item Description </label>
                                <input type="text" id="itemDescription" name="lname"
                                    onChange={this.updateProps}></input>
                                <label htmlFor="lname">Price </label>
                                <input type="text" id="itemPrice" name="lname"
                                    onChange={this.updateProps}></input>
                                <label htmlFor="lname">Quantity </label>
                                <input type="text" id="itemQuantity" name="lname"
                                    onChange={this.updateProps}></input>
                                <br/><br/>

                                <div id="category-btns">
                                    <input style={{opacity: "100%", pointerEvents: "auto"}} 
                                        type="radio" 
                                        id="tshirts" 
                                        name="category" 
                                        value="tshirts"
                                        onClick={this.updateSizeOptions}/>
                                    <label htmlFor="tshirts">T-Shirt</label><br/>
                                    <input style={{opacity: "100%", pointerEvents: "auto"}} 
                                        type="radio" 
                                        id="shirts" 
                                        name="category" 
                                        value="shirts"
                                        onClick={this.updateSizeOptions}/>
                                    <label htmlFor="hoodies">Shirt/Polo</label><br/>
                                    <input style={{opacity: "100%", pointerEvents: "auto"}} 
                                        type="radio" 
                                        id="jackets" 
                                        name="category" 
                                        value="jackets"
                                        onClick={this.updateSizeOptions}/>
                                    <label htmlFor="hoodies">Jacket</label><br/>
                                    <input style={{opacity: "100%", pointerEvents: "auto"}} 
                                        type="radio" 
                                        id="accessories" 
                                        name="category" 
                                        value="accessories"
                                        onClick={this.updateSizeOptions}/>
                                    <label htmlFor="hoodies">Accessory</label><br/>
                                    <input style={{opacity: "100%", pointerEvents: "auto"}} 
                                        type="radio" 
                                        id="hoodies" 
                                        name="category" 
                                        value="hoodies"
                                        onClick={this.updateSizeOptions}/>
                                    <label htmlFor="hoodies">Hoody/Crew</label><br/>
                                    <input style={{opacity: "100%", pointerEvents: "auto"}} 
                                        type="radio" 
                                        id="bottoms" 
                                        name="category" 
                                        value="bottoms"
                                        onClick={this.updateSizeOptions}/>
                                    <label htmlFor="hoodies">Bottom</label><br/>
                                    <input style={{opacity: "100%", pointerEvents: "auto"}} 
                                        type="radio" 
                                        id="accessories" 
                                        name="category" 
                                        value="accessories"
                                        onClick={this.updateSizeOptions}/>
                                    <label htmlFor="accessories">Misc.</label><br/>
                                </div>

                                {this.state.sizeOptions}

                                <SimpleReactFileUpload 
                                    imageAlt={this.state.name} 
                                    itemName={this.state.name} 
                                    itemDescription={this.state.description} 
                                    itemSize={this.state.size}
                                    itemCategory={this.state.selected} 
                                    itemPrice={this.state.price} 
                                    itemQty={this.state.quantity}
                                    uploadItem={false} 
                                /><br/><br/>

                            {this.state.uploadBtn}
                        </div>
                    </div>
                )
            }}
        </Query>);
    }
}

export default AdminView;
