import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToCart } from '../../../components/actions/cartActions';
import '../../../Item.css';
import CSS from 'csstype';
import Logo from '../../../components/Little-Logo-Button';
import CartBtn from '../../../components/Cart-Button';
import WishBtn from '../../../components/Wish-Button';
import PhotoNavigation from "../../../components/Photo-Album-Navigation";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import { MeQuery } from '../../../schemaTypes';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const meQuery = gql`
    query MeQuery {
        me {
            id
            email
        }
    }
`;


export interface Props{
}

export interface State{
    itemId: number;
    itemName: string;
    itemPhotos: string;
    itemSize: string;
    fontStyles: CSS.Properties;
}

const response = async(itemId: any): Promise<any[]> => {
    
    // Single item query.
    const response = await fetch(`https://graphql.membergs.com/graphql?query={getProduct(id: `+itemId+`){id price inventory name category size description photo_album photo_preview}}`);
    const json = await response.json();
    const jsonResult = json["data"]["getProduct"];

    return new Promise((resolve) => {
        resolve(jsonResult);
    });
}

class Item extends Component<any,State,any>{

    constructor(props: Props) {
        super(props); 

        this.state = {
            itemId: 0, 
            itemName: "",
            itemPhotos: "",
            itemSize: "",
            fontStyles: {
                color: 'black',
                fontSize: '16px',
                paddingRight: '20px'
            }
        }

    }

    changeInformation(data: any){

        this.setState({
            itemName: data["name"]
        });

        return true;
    }

    componentDidMount(){

        const requestedProduct: number = Number(this.props.match.params.product_id);
        const currentProductId: number = this.state.itemId;

	    console.log(`${currentProductId} ${requestedProduct}\n`);

        if(requestedProduct != currentProductId){

            this.setState({
                itemId: requestedProduct
            });

            // Fix this??? Throwing an error currently. Moving forward.
            response(requestedProduct).then(function (itemLoaded) {
                //const callMe = () => this.changeInformation(itemLoaded);
            });

        }

    }

    render(){

        let image_Count: number;

        let findItem = this.props.items.map( (item: any) => {

                // Checks to see if we are going to render the 
                // appropriate categorical item

                if(item.id == this.state.itemId){
                    console.log(item);

                    image_Count = (item.img_library).length;
                    let size = '';
                    if(item.size === ''){
                        size = 'O/S'
                    }else{
                        size = item.size;
                    }
                    
                    return(
                        <div className="grid-container-6">
                            <div className="Product-Information">
                                <div style={{paddingLeft: "75px"}} >
                                    <span className="title" style={{paddingLeft: "90px", paddingBottom: "100px"}}>{item.title}</span>
                                    <br/><br/>
                                    <span className="title" style={{fontSize: "18px", paddingLeft: "90px", paddingBottom: "100px"}}>Price: ${item.price}</span>
                                    <br/>
                                    <span className="title" style={{fontSize: "18px", paddingLeft: "90px", paddingBottom: "20px"}}>Qty: {item.inventory}</span>
                                    <div style={{paddingLeft: "90px", paddingTop: "50px"}}>
                                        <div className="square" style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <span className="title" style={{color:"white"}}>{size}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Product-Name">
                            </div>
                            <div className="Product-Preview">
                                <PhotoNavigation photo_count={image_Count} photo_album={item.img_library}/>
                            </div>
                            <div className="Buttons">
                                <br/>
                                <CartBtn item_id={item.id}/>
                                <br/>
                                <WishBtn/>
                            </div>
                        </div>
                    )
                    }

        })

        let findItemMobile = this.props.items.map( (item: any) => {

            // Checks to see if we are going to render the 
            // appropriate categorical item

            if(item.id == this.state.itemId){
                image_Count = (item.img_library).length;

                let size = '';
                if(item.size === ''){
                    size = 'O/S'
                }else{
                    size = item.size;
                }
                
                return(
                    <div className="grid-container-666">
                        <div className="container_row" style={{paddingLeft: "50px"}}>
                            <div className="square" style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                            }}>
                                <div className="title" style={{color:"white" }}>{size}</div>
                            </div>
                        </div>
                        <br/>
                        <div className="container_row">
                            <div className="Product-Preview" style={{width: "50%", paddingLeft: "50px"}}>
                                <PhotoNavigation photo_count={image_Count} photo_album={item.img_library} />
                            </div>
                        </div>
                        <br/><br/><br/><br/><br/>
                        <div className="container_row">
                            <div className="Product-Name">
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="container_row">
                            <div className="Product-Information">
                                <div style={{paddingLeft: "50px"}}>
                                    <span className="title" style={{fontSize: "36px"}} >{item.title}</span>
                                    <br/><br/>
                                    <span className="title" style={{fontSize: "18px"}}>Price: ${item.price}</span>
                                    <br/>
                                    <span className="title" style={{fontSize: "18px"}}>Qty: {item.inventory}</span>
                                </div>
                            </div>
                        </div>
                        <div className="container_row">
                            <div className="Buttons">
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <CartBtn item_id={item.id}/>
                                <br/>
                            </div>
                        </div>

                    </div>
                )
                }

    })

        return(
            <div>
                <MobileView>
                    <div className="grid-container-565">
                        <div className="container_row">
                            <div className="Logo">
                                <Link to="/">
                                    <Logo/>
                                </Link>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Query<MeQuery> 
                            query={meQuery}>{({data, loading}) => { 

                                if(loading) { 
                                    return (
                                        <div className="User-Links-Mobile">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                            <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                        </div>
                                    )
                                }
                
                                if(!data){
                                    return (
                                        <div className="User-Links-Mobile">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                            <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                        </div>
                                    )
                                }
                
                                if(!data.me){
                                    return (
                                        <div className="User-Links-Mobile">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                            <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                        </div>
                                    )
                                }
                                return(
                                    <div className="User-Links-Mobile">
                                        <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                    </div>
                                )  
                            }}
                        </Query>
                        <br/>
                        <br/>
                        <div className="container_row">
                            <div className="Product-Information">
                                {findItemMobile}
                            </div>
                        </div>
                    </div>

                </MobileView>
                <BrowserView>
                    <div className="grid-container-5">
                        <div className="Logo">
                            <Link to="/">
                                <Logo/>
                            </Link>
                        </div>
                        <Query<MeQuery> 
                            query={meQuery}>{({data, loading}) => { 

                                if(loading) { 
                                    return (
                                        <div className="User-Links">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                            <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                        </div>
                                    )
                                }
                
                                if(!data){
                                    return (
                                        <div className="User-Links">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                            <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                        </div>
                                    )
                                }
                
                                if(!data.me){
                                    return (
                                        <div className="User-Links">
                                            <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                            <a><Link to="/login" style={this.state.fontStyles}>Login</Link></a>
                                        </div>
                                    )
                                }
                                return(
                                    <div className="User-Links">
                                        <a><Link to="/cart" style={this.state.fontStyles}>Cart</Link></a> 
                                    </div>
                                )  
                            }}
                        </Query>
                        <div className="Product-Information">
                            {findItem}
                        </div>
                    </div>
                </BrowserView>
            </div>
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
      items: state.items
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return{   
        addToCart: (id: any) => { dispatch(addToCart(id)) }     
    }
}
  // @ts-ignore: Unreachable code error
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Item))

