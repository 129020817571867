import React from 'react'
import axios from 'axios';

interface State{
    files: any[];
    imageUrls: any[];
    uploadBtns: any[];
    filesUploaded: boolean;
}

interface Props{
  imageAlt: any;
  itemName: any;
  itemDescription: any;
  itemSize: any;
  itemPrice: number;
  itemQty: number;
  itemCategory: any;
  uploadItem: boolean;
}

class SimpleReactFileUpload extends React.Component<Props,State,any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      files:[],
      imageUrls: [],
      uploadBtns: [],
      filesUploaded: false
    }

    this.onFileUploadChange = this.onFileUploadChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updatePhotoButtons = this.updatePhotoButtons.bind(this);
    this.uploadProduct = this.uploadProduct.bind(this);
    this.uploadItem = this.uploadItem.bind(this);
  }

  // Makes the appropriate graphql query in order
  // to add this product to our database of products.
  uploadItem(){

    let queryUrl: string = 'https://graphql.membergs.com/graphql';
    axios({
      url: queryUrl,
      method: 'post',
      data: {
        query:`mutation{
          addProduct(
              price: ${Number(this.props.itemPrice)}, 
              amount: ${Number(this.props.itemQty)}, 
              name: "${this.props.itemName}", 
              type_of_product: "${this.props.itemCategory}", 
              product_size: "${this.props.itemSize}", 
              description: "${this.props.itemDescription}", 
              photo_album: [${this.state.imageUrls}],
              photo_preview: ${(this.state.imageUrls[0])}
            )
        }
        `
      }
    }).then((result) => {
      console.log("ITEM UPLOADED");
      console.log(result);
      window.location.reload();
        // @ts-ignore: Unreachable code error
    }).catch((err) => { console.log(err); });

  }

  componentDidMount(){
  }

  onChange(e: any) {
  }

  uploadProduct(e: any){

  }

  onFileUploadChange(e: any) {
    let currentFiles: any[] = this.state.files;

    currentFiles.push(e.target.files[0]);

    this.setState({
      files:currentFiles
    });
  }

  updatePhotoButtons(e: any){
    let currentButtons: any[] = this.state.uploadBtns;
    let buttonCount: number = (currentButtons).length;

    let htmlId: string = `${buttonCount}`;
    
    currentButtons.push(
      <input className="UploadButton" id={htmlId} type="file" onChange={this.onFileUploadChange} />
    );

    this.setState({
      uploadBtns: currentButtons
    });
  }
  
  fileUpload(e: any){

    e.preventDefault();

    let filesAdded: number = (this.state.files).length;
    let linksRetreived: string[] = [];
    let i: number = 0;

    while( i < filesAdded ){
      const formData = new FormData();
      formData.append('file',this.state.files[i]);
      formData.append('upload_preset', 'nhohomhk');

      axios.post<any>(
        'https://api.cloudinary.com/v1_1/membergs/image/upload',
        formData
      ).then(response => {
        linksRetreived.push("\"" + response.data.secure_url + "\"");
      }).catch((error) => {
        console.log("ERROR!: " + error);
      })

      i+=1;
    }

    this.setState({
      imageUrls: linksRetreived,
      filesUploaded: true
    });

    /* For Debugging!
    axios.interceptors.request.use(request => {
      console.log('Starting Request', request)
      return request
    }); */
    
    /* For Debugging!
    axios.interceptors.response.use(response => {
      console.log('Response:', response)
      return response
    }); 
    
    
    */
    

  }
  

  render() {
    
    if(this.state.filesUploaded == true){
        return (
            <div>
                    <h3>Files Uploaded!</h3>
                    <button 
                      onClick={this.uploadItem} 
                      style={{backgroundColor: "#02FBF7"}}
                      className="waves-effect waves-light btn remove"  
                      value="Upload Product" >Upload Product!!</button>
                    <br/>
                    <br/><br/>
            </div>
      )
    } else {
      return (
            <div>
                {
                  this.state.uploadBtns.map((item) => (
                    item
                  ))
                }
                <br/>
                <input 
                  type="button" 
                  style={{backgroundColor: "#02FBF7"}}
                  className="waves-effect waves-light btn remove"  
                  value="Add Another Photo"  
                  onClick={this.updatePhotoButtons}></input>
                <br/><br/>
                <button 
                  onClick={this.fileUpload} 
                  style={{backgroundColor: "#02FBF7"}}
                  className="waves-effect waves-light btn remove"  
                  value="Upload Photo" >Upload Photo(s)!</button>
            </div>
      )
    }
    
  }
}



export default SimpleReactFileUpload;
