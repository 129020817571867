import React, { Component } from 'react';
import CSS from 'csstype';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import { Frame, Page } from "framer";

export interface State{
    photoPage: number;
    navigationStyle: CSS.Properties;
    showCircles: boolean;
}
export interface Props{
}

class PhotoNavigation extends Component<any,State,any>{

    constructor(props: Props){
        super(props);

        this.state = { photoPage: 1, navigationStyle: {display: "inline"}, showCircles: true };

        this.updatePhotoPrevious = this.updatePhotoPrevious.bind(this);
        this.updatePhotoNext = this.updatePhotoNext.bind(this);
        this.renderPages = this.renderPages.bind(this);
        this.renderFrames = this.renderFrames.bind(this);
    }

    updatePhotoPrevious(e: any){
        var newPage = this.state.photoPage;
        newPage -= 1;

        if(newPage > 0){
            console.log(newPage);
            this.setState({
                photoPage: newPage
            })
        }
    }

    updatePhotoNext(e: any){
        var newPage = this.state.photoPage;
        newPage += 1;

        if( newPage <= this.props.photo_count){
            console.log(newPage);
            this.setState({
                photoPage: newPage
            })
        }
    }

    renderFrames(){
        let i = 0;
        let photoCount = this.props.photo_count;
        const frames = [];

        while(i < photoCount){
            frames.push(
                <Frame 
                    size={275}
                    radius={20}
                    background={{src: this.props.photo_album[i]}} />
            )
            i += 1;
        }

        return(frames);
    }

    renderPages(){
        return(                            
            <Page width={275} height={275} radius={25} >
                {this.renderFrames()}
            </Page>
            )
    }

    render(){

        let count: any = this.props.photo_count;
        let i = 0;
        var arr = [];
        
        if(count <= 1){
            if(this.state.showCircles  == true)
            {
                this.setState({
                    navigationStyle: {display: "none"},
                    showCircles: false
                });
            }
        }else{
            while( (i < count) ){

                if((i+1) == (this.state.photoPage)) {
    
                    arr.push(
                        <div id="circle" className="circle">
                            <svg width="52px" height="52px" viewBox="0 0 52 52" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
                                    <circle id="Oval-1" stroke="#000000" stroke-width="7" fill="#000000" cx="26" cy="26" r="22"></circle>
                                </g>
                            </svg>
                        </div>
                    );
    
                } else {
    
                    arr.push(
                        <div id="circle" className="circle">
                            <svg width="52px" height="52px" viewBox="0 0 52 52" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
                                    <circle id="Oval-1" stroke="#000000" stroke-width="7" fill="#FFFFFF" cx="26" cy="26" r="22"></circle>
                                </g>
                            </svg>
                        </div>
                    );
    
                }
    
                i+=1
            }
        }

        // <img src={this.props.photo_album[(this.state.photoPage)-1]} style={{height: "275px", width:"275px"}}/>
        return(
            <div>
                <BrowserView>
                    <div className="Product-Preview-Inner">
                        <div className="Photo">
                            <img src={this.props.photo_album[(this.state.photoPage)-1]} style={{height: "450px", width:"450px", float:"left"}}/>
                        </div>
                        <br/>
                        <div className="Navi-Bar" >
                            <div id="left-arrow" className="left-arrow" style={this.state.navigationStyle}>
                                <svg 
                                    width="34px" 
                                    height="46px" 
                                    viewBox="0 0 34 46" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    onClick={this.updatePhotoPrevious}>
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
                                        <path d="M33,1 L33,45 L0,23 L33,1 Z" id="Triangle-1" stroke="#000000" fill="#000000" />
                                    </g>
                                </svg>
                            </div>
                            {arr}
                            <div id="right-arrow" className="right-arrow" style={this.state.navigationStyle}>
                                <svg 
                                    width="34px" 
                                    height="46px" 
                                    viewBox="0 0 34 46" 
                                    version="1.1" 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    onClick={this.updatePhotoNext}>
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" >
                                        <path d="M34,1 L34,45 L1,23 L34,1 Z" id="Triangle-1" stroke="#000000" fill="#000000" transform="translate(17.500000, 23.000000) rotate(-180.000000) translate(-17.500000, -23.000000) "/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="Product-Preview-Inner">
                        <div className="Photo">
                            {this.renderPages()}
                        </div>
                    </div>
                </MobileView>
            </div>
        )
    }
}


export default PhotoNavigation;