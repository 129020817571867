import * as React from "react";
import { Query } from 'react-apollo';
import { gql } from 'apollo-boost';
import { MeQuery } from '../../../schemaTypes';

const meQuery = gql`
    query MeQuery {
        me {
            id
            email
        }
    }
`;

class MeView extends React.PureComponent<any,any,any> {

    componentDidMount() {
	this.forceUpdate();

    }

    render() {
        return( 
        <Query<MeQuery> 
            query={meQuery}>{({data, loading}) => { 

                console.log(data);
                
                if(loading) { 
                    return null;
                }

                if(!data){
                    return <div>Incorrect Email or Password!</div>;
                }

                if(!data.me){
                    return <div>Unauthorized Entry!</div>;
                }

                return <div>{data.me.email}</div>;
                

            }}
        </Query>);
    }
}

export default MeView;
