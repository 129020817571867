import * as React from 'react';
import { Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import { LoginMutation, LoginMutationVariables } from '../../../schemaTypes';
import { RouteComponentProps } from 'react-router';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const loginMutation = gql`
    mutation LoginMutation($email: String!, $password: String!){
        login(email: $email, password: $password){
            id
            email
        }
    }
`;

class LoginView extends React.PureComponent<RouteComponentProps<{}>> {

    state = {
        email: '',
        password: ''
    }

    handleChange = (e: any) => {
        const { name, value } = e.target;
        this.setState({
            [name]:value
        });
    };

    render(){
        const {password, email} = this.state;

        return(
            <Mutation<LoginMutation, LoginMutationVariables> mutation={loginMutation}>
                {mutate => (
                        <div className="adminLogin">
                            <input 
                                type="text"
                                placeholder="email" 
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                            />
                            <input 
                                type="password"
                                placeholder="password" 
                                name="password"
                                value={password}
                                onChange={this.handleChange}
                            />
                            <button onClick={async () => {
                                const Response: any = await mutate({variables: this.state});
                                const loginSuccess = Response['data']['login'];
                                if(loginSuccess == null){
                                    alert("Incorrect email/password combination!\n");
                                }else{
                                    this.props.history.push('/store');
                                    alert("Login Success!\n");
                                }
                            }}>
                                Enter
                            </button>
                            <AudioPlayer
                                    autoPlay
                                    src="http://example.com/audio.mp3"
                                    onPlay={e => console.log("onPlay")}
                                    // other props here
                            />
                        </div>
                )}
            </Mutation>
        );
    }
}
export default LoginView;
