import * as React from 'react';
import { connect } from 'react-redux';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { removeItem,addQuantity,subtractQuantity} from '../../../components/actions/cartActions';
import Logo from '../../../components/Little-Logo-Button';
import PayPal from '../../../components/PayPal';
import '../../../Cart.css';
import '../../../InCart.css';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export interface State {
    totalAmount: number;
}

export interface Props {

}

class Cart extends Component<any, State, any>{

    constructor(props: Props) {
        super(props); // Because this class inherts the React.Component, this will initialize the Component itself.
        
        this.state = { 
            totalAmount: 0.0
        };

    }

    handleRemove = (id: any) => {
        this.props.removeItem(id);
    }

    handleAddQuantity = (id: any) => {

        let i: number = 0;
        let stop: number = this.read_only_items.length;
        let item: any;
        let isFound: boolean = false;

        while((i < stop) && (isFound === false)){
            item = this.read_only_items[i];
            if(item.id === id){
                isFound = true
            }
            i+=1;
        }

        if(item.quantity < item.inventory){
            this.props.addQuantity(id);
        }
    }

    handleSubtractQuantity = (id: any) => {
        this.props.subtractQuantity(id);
    }

    updatePrice(amount: any) {
        this.setState({
            totalAmount: amount
        })
    }

    private isTrue: boolean = false;

    private read_only_items: any[] = this.props.items.map((item: any) => {return(item)})

    private testCard = () => { return ( <></> ) };


    render(){   

        let priceTotal: any = 0.0;
        let priceTotalMobile: any = 0.0;
        let itemIds: any = [];

        let addedItems = this.props.items.length ?
            (  
                this.props.items.map((item: any)=>{

                    let inventory: Number = item.inventory;
                    let thePrice: Number = Number(item.price);
                    let theQuantity: Number = Number(item.quantity);
                    priceTotal += (+thePrice * +theQuantity);

                    if(inventory > 1){
                        itemIds.push(item.id);
                        return(
                            <li className="collection-item avatar" key={item.id} style={{background: "#EBE8E8"}}>
                                <div className="grid-container-4">
                                    <div className="Image">
                                        <div className="item-img"> 
                                            <img src={item.img_preview} alt={item.img_preview} className=""/>
                                        </div>
                                    </div>
                                    <div className="Name">
                                        <span style={{fontSize: "24px"}} className="title">{item.title}</span>
                                    </div>
                                    <div className="Size">
                                        <span className="title" >Size</span>
                                        <div className="square">
                                            <span className="title" style={{textAlign:"center", paddingTop: "10px", color:"white"}}>M</span>
                                        </div>
                                    </div>
                                    <div className="Quantity">
                                        <p>
                                            <span className="title">Quantity</span>
                                            <span className="title">{item.quantity}</span>
                                        </p>
                                    </div>
                                    <div className="Price">
                                        <p>
                                            <span className="title">Price</span>
                                            <span className="title">{item.price}$</span>
                                        </p> 
                                    </div>
                                    <div className="Remove-Btn">
                                        <button 
                                            className="waves-effect waves-light btn remove" 
                                            style={{backgroundColor: "#1f1f1f"}}
                                            onClick={()=>{this.handleRemove(item.id)}}>Remove</button>
                                    </div>
                                </div>
                            
                                <div className="item-desc" style={{display: "None"}}>
                                    <div className="add-remove">
                                        <Link to="/cart"><i className="material-icons" onClick={()=>{this.handleAddQuantity(item.id)}}>arrow_drop_up</i></Link>
                                        <Link to="/cart"><i className="material-icons" onClick={()=>{this.handleSubtractQuantity(item.id)}}>arrow_drop_down</i></Link>
                                    </div>
                                </div>
                            </li>
                        )
                    }else{
                        itemIds.push(item.id);
                        return(
                            <li className="collection-item avatar" key={item.id} style={{background: "#EBE8E8"}}>
                                <div className="grid-container-4">
                                    <div className="Quantity">
                                        <p>
                                            <span className="title">Quantity</span>
                                            <span className="title">{item.quantity}</span>
                                        </p>
                                    </div>
                                    <div className="Price">
                                        <p>
                                            <span className="title">Price</span>
                                            <span className="title">{item.price}$</span>
                                        </p> 
                                    </div>
                                    <div className="Image">
                                        <div className="item-img"> 
                                            <img src={item.img_preview} alt={item.img_preview} className=""/>
                                        </div>
                                    </div>
                                    <div className="Name">
                                        <span className="title">{item.title}</span>
                                    </div>
                                    <div className="Size">
                                        <span className="title" >Size</span>
                                        <div className="square">
                                            <span className="title" style={{textAlign:"center", paddingTop: "10px", color:"white"}}>M</span>
                                        </div>
                                    </div>
                                    <div className="Remove-Btn">
                                        <button 
                                            className="waves-effect waves-light btn remove" 
                                            style={{backgroundColor: "#1f1f1f"}}
                                            onClick={()=>{this.handleRemove(item.id)}}
                                        >Remove</button>
                                    </div>
                                </div>
                    
                            </li>
                        )
                    }

                })
            ):(
                <p style={{textAlign: "center"}}>Empty cart.</p>
             );

        let addedItemsMobile = this.props.items.length ?
             (  
                 this.props.items.map((item: any)=>{
 
                     let inventory: Number = item.inventory;
                     let thePrice: Number = Number(item.price);
                     let theQuantity: Number = Number(item.quantity);
                     priceTotalMobile += (+thePrice * +theQuantity);
 
                     if(inventory > 1){
                         itemIds.push(item.id);
                         return(
                             <li className="collection-item" key={item.id} style={{background: "#EBE8E8"}}>
                                 <div className="mobile-cart-container">
                                     <div className="mobile-picture">
                                         <div className="item-img"> 
                                             <img src={item.img_preview} alt={item.img_preview} className=""/>
                                         </div>
                                     </div>
                                     <div className="mobile-product-name">
                                         <span style={{fontSize: "24px"}} className="title">{item.title}</span>
                                     </div>
                                     <div className="mobile-size">
                                        <span className="title" >Size</span>
                                        <div className="container_row" ><br/>
                                            <div className="square" >
                                                <div className="title" style={{textAlign:"center", color:"white", paddingTop: "10px"}}>M</div>
                                            </div>
                                        </div>
                                     </div>
                                     <div className="mobile-quantity">
                                         <p>
                                             <span className="title">Quantity</span>
                                             <span className="title">{item.quantity}</span>
                                         </p>
                                     </div>
                                     <div className="mobile-price">
                                         <p>
                                             <span className="title">{item.price}$</span>
                                         </p> 
                                     </div>
                                     <div className="mobile-remove">
                                         <button 
                                             className="waves-effect waves-light btn remove" 
                                             style={{backgroundColor: "#1f1f1f"}}
                                             onClick={()=>{this.handleRemove(item.id)}}>Remove</button>
                                     </div>
                                 </div>
                             
                                 <div className="item-desc" style={{display: "None"}}>
                                     <div className="add-remove">
                                         <Link to="/cart"><i className="material-icons" onClick={()=>{this.handleAddQuantity(item.id)}}>arrow_drop_up</i></Link>
                                         <Link to="/cart"><i className="material-icons" onClick={()=>{this.handleSubtractQuantity(item.id)}}>arrow_drop_down</i></Link>
                                     </div>
                                 </div>
                             </li>
                         )
                     }else{
                         itemIds.push(item.id);
                         return(
                             <li className="collection-item" key={item.id} style={{background: "#EBE8E8"}}>
                                 <div className="mobile-cart-container">
                                     <div className="mobile-quantity">
                                         <p>
                                             <span className="title">Qty: </span>
                                             <span className="title">{item.quantity}</span>
                                         </p>
                                     </div>
                                     <div className="mobile-price">
                                         <p>
                                             <span className="title">{item.price}$</span>
                                         </p> 
                                     </div>
                                     <div className="mobile-picture">
                                         <div className="item-img"> 
                                             <img src={item.img_preview} alt={item.img_preview} className=""/>
                                         </div>
                                     </div>
                                     <div className="mobile-product-name">
                                         <span className="title">{item.title}</span>
                                     </div>
                                     <div className="mobile-size">
                                         <span className="title" >Size</span><br/>
                                         <div className="container_row">
                                            <div className="square" >
                                                <div className="title" style={{textAlign:"center", color:"white", paddingTop: "10px"}}>M</div>
                                            </div>
                                        </div>
                                     </div>
                                     <div className="mobile-remove">
                                         <button 
                                             className="waves-effect waves-light btn remove" 
                                             style={{backgroundColor: "#1f1f1f"}}
                                             onClick={()=>{this.handleRemove(item.id)}}
                                         >Remove</button>
                                     </div>
                                 </div>
                     
                             </li>
                         )
                     }
 
                 })
             ):(
                 <p style={{textAlign: "center"}}>Empty cart.</p>
              );

        if(priceTotal > 0){
            return(
                <div>
                    <BrowserView>
                        <div className="grid-container-3">
                            <div className="Logo">
                                <Link to="/">
                                    <Logo/>
                                </Link><br/>
                            </div>
                            <div className="Item-List">
                                <div className="cart">
                                    <ul className="collection" style={{background: "#EBE8E8", paddingBottom: "25px", paddingTop: "25px"}}>
                                        {addedItems}
                                        <hr style={{width: "80%"}}/>
                                        <b style={{float: "right", paddingRight: "25px", background: "#EBE8E8"}}>${priceTotal}</b>
                                        <a style={{float: "right", paddingRight: "55px", background: "#EBE8E8", color: "black"}}>Total</a>
                                    </ul>
                                </div> 
                            </div>
                            <div className="Checkout-Options">
                                <script src="https://www.paypal.com/sdk/js?client-id=sb" />
                                {this.testCard}
                                <PayPal totalAmt={priceTotal} itemIds={itemIds}/>
                            </div>
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className="grid-container-3-mobile">
                            <div className="Logo">
                                <Link to="/">
                                    <Logo/>
                                </Link><br/>
                            </div>
                            <div className="Item-List">
                                <div className="cart">
                                    <ul className="collection" style={{background: "#EBE8E8", paddingBottom: "25px", paddingTop: "25px"}}>
                                        {addedItemsMobile}
                                        <hr style={{width: "80%"}}/>
                                        <b style={{float: "right", paddingRight: "25px", background: "#EBE8E8"}}>${priceTotalMobile}</b>
                                        <a style={{float: "right", paddingRight: "55px", background: "#EBE8E8", color: "black"}}>Total</a>
                                    </ul>
                                </div> 
                            </div>
                            <div className="Checkout-Options">
                                <script src="https://www.paypal.com/sdk/js?client-id=sb" />
                                {this.testCard}
                                <PayPal totalAmt={priceTotalMobile} itemIds={itemIds}/>
                            </div>
                        </div>
                    </MobileView>
                </div>
           )
        }else{
            return(
                <div>
                    <BrowserView>
                        <div className="grid-container-3">
                            <div className="Logo">
                                <Link to="/">
                                    <Logo/>
                                </Link><br/>
                            </div>
                            <div className="Item-List">
                                <div className="cart">
                                    <ul className="collection" style={{background: "#EBE8E8", paddingBottom: "25px", paddingTop: "25px"}}>
                                        {addedItems}
                                    </ul>
                                </div> 
                            </div>
                            <div className="Checkout-Options">
                            </div>
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className="grid-container-3-mobile">
                            <div className="Logo">
                                <Link to="/">
                                    <Logo/>
                                </Link><br/>
                            </div>
                            <div className="Item-List">
                                <div className="cart">
                                    <ul className="collection" style={{background: "#EBE8E8", paddingBottom: "25px", paddingTop: "25px"}}>
                                        {addedItemsMobile}
                                    </ul>
                                </div> 
                            </div>
                            <div className="Checkout-Options">
                            </div>
                        </div>
                    </MobileView>
                </div>
           )
        }
    }
}

const mapStateToProps = (state: any) => {
    return{
        items: state.addedItems
    }
}
const mapDispatchToProps = (dispatch: any)=>{
    return{
        removeItem: (id: any)=>{dispatch(removeItem(id))},
        addQuantity: (id: any)=>{dispatch(addQuantity(id))},
        subtractQuantity: (id: any)=>{dispatch(subtractQuantity(id))}
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Cart)