import React from 'react';
import { Route, Switch } from 'react-router-dom';
import '../App.css';
import Logo from '../components/Big-Logo-Button'
import "reflect-metadata";
import LoginView from './Store/modules/Login';
import { BrowserRouter } from 'react-router-dom';
import RegisterView from './Store/modules/Register';
import MeView from './Store/modules/Me';
import ProductsPage from './Store/modules/Products';
import Cart from './Store/modules/Cart';
import StoreHome from './Store/modules/Store';
import Item from './Store/modules/Item';
import AdminView from './Store/modules/Admin';
import AddProduct from './Store/modules/Admin/Add-Product';
import RemoveProduct from './Store/modules/Admin/Remove-Product'
import HttpsRedirect from 'react-https-redirect';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import BlogRedirect from '../components/Redirect';

const Root = () => {
    return (
	<HttpsRedirect>
		<BrowserRouter>
			<div className="App">
				<header className="App-header">
					<Switch>
					<Route exact path='/' component={Logo}/>                    
					<Route exact path='/store/products/:category/:page_number' component={ProductsPage} />
					<Route exact path='/item/:product_id' component={Item}/>
					<Route exact path='/store/'  component={StoreHome} />
					<Route exact path='/cart'  component={Cart} />
					<Route exact path='/blog/redirect' component={BlogRedirect}/>
					<Route exact path="/login"  component={LoginView} />
					<Route exact path="/register"  component={RegisterView} />
					<Route exact path='/me'  component={MeView} />
					<Route exact path='/insearchof/'  component={AdminView} />
					<Route exact path='/sinkorswim'  component={RemoveProduct} />
					</Switch>
				</header>
			</div>
			<div className="audioPlayer">
				<AudioPlayer
					autoPlay
					src="https://audio.jukehost.co.uk/zG2czFnOHClcl2tKbjf7B5VxXPWNuwXl"
					onPlay={e => console.log("onPlay")}
					showJumpControls={false}
					showDownloadProgress={false}
					showFilledProgress={false}
					showFilledVolume={false}
					showSkipControls={false}
				/>
			</div>
		</BrowserRouter>
	</HttpsRedirect>
    );
};

export default Root;
